// ----------------------------------------------------------------------

export default function PaginationItem(theme) {
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          // bgcolor: theme => theme.palette.background.paper,
          border: '1px solid #EDEFF1',
          marginLeft: 0,
          marginRight: 0,
        },
        page: {
          borderRadius: 0,
        },
        previousNext: {
        },
        firstLast: {
        },
        selected: {
          // bgcolor: theme => theme.palette.primary.lighter,
          // color: theme => theme.palette.primary.main,
          // border: '1px solid',
          // borderColor: theme => theme.palette.primary.light,
        }
      },
    },
  };
}
