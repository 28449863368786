import * as React from 'react';
import {createRoot} from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import ThemeProvider from './theme';
import {Router} from "react-router";
import {createBrowserHistory} from 'history';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
const history = createBrowserHistory();

root.render(
    <Router history={history}>
        <App/>
    </Router>
);
