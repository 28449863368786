import React, {useState} from 'react';
import {Badge, Box, IconButton, Menu, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import moment from "moment-timezone";

const Notification = () => {
    const {t, i18n: {language}} = useTranslation();
    moment.locale(language);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const [badgeContent, setBadgeContent] = useState(0);


    const [activeTab, setActiveTab] = useState(0);

    return (
        <div>
            <IconButton
                onClick={handleClick}
                aria-controls={open ? 'notifications-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                {
                    badgeContent > 0 ? (
                        <Badge color={"error"} max={99} badgeContent={badgeContent}>
                            <img src="/icons/notifications.svg" alt=""/>
                        </Badge>
                    ) : (
                        <img src="/icons/notifications.svg" alt=""/>
                    )
                }
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                id="notifications-menu"
                open={open}
                onClose={handleClose}
                elevation={1}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: "50%",
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'center', vertical: 'top'}}
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
            >
                <Box
                    sx={{
                        width: {
                            xs: 320,
                            sm: 367,
                        },
                        px: "20px"
                    }}
                >
                    <Typography
                        variant={"h4"}
                        color={"#0A0500"}
                        sx={{mb: 3}}
                    >
                        {t('global_label_notifications')}
                    </Typography>

                    <Box
                        sx={{mb: 4}}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-start",
                                textAlign: "center",
                                position: "relative",
                            }}
                        >
                            <Box
                                sx={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setActiveTab(0)
                                }}
                            >
                                <Typography
                                    color={activeTab == 0 ? "#4A4FDD" : "#9F9996"}
                                    variant={activeTab == 0 ? "body1" : "body2"}
                                    sx={{
                                        px: "20px"
                                    }}
                                >
                                    {t('label_unread')}
                                </Typography>
                                <Box
                                    sx={{
                                        position: "relative",
                                        mr: activeTab == 0 ? "-10px" : 0,
                                        borderRadius: "10px",
                                        zIndex: activeTab == 0 ? 2 : 1,
                                        height: "4px",
                                        backgroundColor: activeTab == 0 ? '#282B89' : "rgba(217,217,217,0.5)"
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setActiveTab(1)
                                }}
                            >
                                <Typography
                                    color={activeTab == 1 ? "#4A4FDD" : "#9F9996"}
                                    variant={activeTab == 1 ? "body1" : "body2"}
                                    sx={{
                                        px: "20px"
                                    }}
                                >
                                    {t('label_all_notification')}
                                </Typography>
                                <Box
                                    sx={{
                                        position: "relative",
                                        borderRadius: "10px",
                                        zIndex: activeTab == 1 ? 2 : 1,
                                        height: "4px",
                                        backgroundColor: activeTab == 1 ? '#282B89' : "rgba(217,217,217,0.5)",
                                        ml: activeTab == 1 ? "-10px" : 0
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Menu>
        </div>

    )
}

interface NotificationItemProps {
    img: string,
    name: string,
    description: string,
    isRead: boolean;
}

// https://files.readtotake.com/images/87eaa5fd5ae1aa84fa3e7e4562c764bd-clatyjgot0000qj014k0wccmg.avif

const NotificationItem = (props: NotificationItemProps) => {
    const {img, name, description, isRead} = props;
    const {t, i18n: {language}} = useTranslation();

    return (
        <Box
            sx={{
                mb: 2
            }}
            
        >

        </Box>
    )
}

export default Notification

