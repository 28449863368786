import { Pagination } from 'types';
import { IEditService } from 'views/services/logic/service_types';
import BaseService from './BaseService';
import {ServiceUrls} from './urls';

class ServiceService {
    static get_service_details = (uuid: string) =>{
        return BaseService.getRequest(ServiceUrls.GET_SERVICE_DETAIL(uuid), true)
    }

    static get_all_services = (range: Pagination)=>{
        return BaseService.getRequest(ServiceUrls.GET_SERVICE_LIST(range), true)
    }

    static get_all_services_categories= (range: Pagination) =>{
        return BaseService.getRequest(ServiceUrls.GET_SERVICE_CATEGORY_LIST(range), true)
    }

    static add_service = (data: IEditService) =>{
        return BaseService.postRequest(ServiceUrls.ADD_SERVICE, data, true)
    }
    static update_service = (data: IEditService) =>{
        return BaseService.putRequest(ServiceUrls.UPDATE_SERVICE, data, true)
    }
    static delete_service = (id: number) =>{
        return BaseService.deleteRequest(ServiceUrls.DELETE_SERVICE(id),{}, true)
    }
}

export default ServiceService;
