import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
// @mui
import {useTheme} from '@mui/material/styles';
import {Box} from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function Logo({disabledLink = false, sx}) {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR
    // const logo = '/logo/logo_single.svg';

    const logo = (
        <Box sx={{width: 64, height: 64, display: 'flex', alignItems: 'center', ...sx}}>
            <svg width="70" height="98" viewBox="0 0 70 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M25.2347 13.1683L25.0503 13.3422C23.042 15.2362 22.946 18.403 24.8358 20.4156L31.1897 27.1824C33.0795 29.1949 36.2395 29.2911 38.2477 27.3972L38.4322 27.2232C40.4404 25.3293 40.5364 22.1625 38.6466 20.1499L32.2927 13.3831C30.4029 11.3705 27.2429 11.2743 25.2347 13.1683Z" fill="#A30041"/>
                <path d="M44.7788 13.4028L44.596 13.2271C42.6057 11.3143 39.4449 11.3807 37.5363 13.3753L31.119 20.0817C29.2104 22.0764 29.2766 25.244 31.2669 27.1568L31.4498 27.3325C33.4401 29.2453 36.6008 29.179 38.5094 27.1843L44.9267 20.4779C46.8353 18.4833 46.7691 15.3156 44.7788 13.4028Z" fill="#FF006D"/>
                <path d="M34.5566 11.388C37.6944 11.388 40.2382 8.83868 40.2382 5.69398C40.2382 2.54928 37.6944 0 34.5566 0C31.4187 0 28.875 2.54928 28.875 5.69398C28.875 8.83868 31.4187 11.388 34.5566 11.388Z" fill="#FF006D"/>
                <path d="M52.9578 17.9876L52.7154 17.9139C50.0766 17.1115 47.2884 18.6047 46.4877 21.2493L43.7955 30.1407C42.9948 32.7852 44.4848 35.5796 47.1236 36.382L47.366 36.4558C50.0048 37.2582 52.793 35.7649 53.5938 33.1204L56.2859 24.229C57.0866 21.5844 55.5966 18.7901 52.9578 17.9876Z" fill="#FF5900"/>
                <path d="M62.4861 35.101L62.5471 34.8546C63.2115 32.1724 61.5804 29.4583 58.904 28.7926L49.9055 26.5541C47.2292 25.8884 44.521 27.523 43.8567 30.2052L43.7957 30.4516C43.1313 33.1338 44.7624 35.8479 47.4388 36.5137L56.4373 38.7521C59.1136 39.4179 61.8218 37.7832 62.4861 35.101Z" fill="#FFAA00"/>
                <path d="M64.0644 28.0657C67.2022 28.0657 69.746 25.5164 69.746 22.3717C69.746 19.227 67.2022 16.6777 64.0644 16.6777C60.9265 16.6777 58.3828 19.227 58.3828 22.3717C58.3828 25.5164 60.9265 28.0657 64.0644 28.0657Z" fill="#FFAA00"/>
                <path d="M62.287 43.7915L62.2313 43.5452C61.6246 40.8645 58.9644 39.1843 56.2896 39.7923L47.2962 41.8365C44.6213 42.4445 42.9447 45.1105 43.5514 47.7911L43.6071 48.0374C44.2138 50.7181 46.874 52.3984 49.5488 51.7904L58.5422 49.7462C61.2171 49.1382 62.8937 46.4722 62.287 43.7915Z" fill="#A4A804"/>
                <path d="M52.5213 61.2957L52.7649 61.2261C55.4167 60.468 56.9532 57.6991 56.1968 55.0415L53.6537 46.1061C52.8973 43.4485 50.1344 41.9086 47.4826 42.6667L47.239 42.7363C44.5872 43.4943 43.0506 46.2633 43.807 48.9209L46.3502 57.8563C47.1065 60.5139 49.8694 62.0537 52.5213 61.2957Z" fill="#DBE000"/>
                <path d="M64.3183 62.0169C67.4562 62.0169 69.9999 59.4676 69.9999 56.3229C69.9999 53.1782 67.4562 50.6289 64.3183 50.6289C61.1804 50.6289 58.6367 53.1782 58.6367 56.3229C58.6367 59.4676 61.1804 62.0169 64.3183 62.0169Z" fill="#DBE000"/>
                <path d="M44.4415 66.0412L44.6273 65.8686C46.6496 63.9898 46.7693 60.8238 44.8947 58.797L38.5916 51.9827C36.717 49.9559 33.5578 49.836 31.5355 51.7147L31.3497 51.8873C29.3273 53.7661 29.2076 56.9321 31.0823 58.9589L37.3853 65.7732C39.26 67.8 42.4192 67.92 44.4415 66.0412Z" fill="#009E99"/>
                <path d="M24.9009 65.6645L25.0824 65.8416C27.0583 67.7693 30.2195 67.7267 32.143 65.7465L38.6103 59.0885C40.5338 57.1083 40.4913 53.9402 38.5154 52.0125L38.3339 51.8354C36.3579 49.9077 33.1968 49.9503 31.2733 51.9305L24.806 58.5885C22.8825 60.5687 22.925 63.7368 24.9009 65.6645Z" fill="#00D2D3"/>
                <path d="M35.0644 79.1419C38.2022 79.1419 40.746 76.5926 40.746 73.4479C40.746 70.3032 38.2022 67.7539 35.0644 67.7539C31.9265 67.7539 29.3828 70.3032 29.3828 73.4479C29.3828 76.5926 31.9265 79.1419 35.0644 79.1419Z" fill="#00D2D3"/>
                <path d="M16.7569 61.0157L16.9987 61.0912C19.6314 61.9135 22.4308 60.4412 23.2513 57.8028L26.0099 48.9318C26.8304 46.2934 25.3614 43.4879 22.7287 42.6657L22.4868 42.5901C19.8541 41.7678 17.0548 43.2401 16.2343 45.8785L13.4756 54.7495C12.6551 57.3879 14.1242 60.1934 16.7569 61.0157Z" fill="#0098FF"/>
                <path d="M7.35284 43.8325L7.28997 44.0784C6.60558 46.7555 8.2163 49.4818 10.8876 50.1677L19.8691 52.4737C22.5404 53.1596 25.2607 51.5454 25.9451 48.8682L26.008 48.6223C26.6923 45.9452 25.0816 43.2189 22.4103 42.533L13.4288 40.227C10.7575 39.5411 8.03722 41.1553 7.35284 43.8325Z" fill="#73CDFF"/>
                <path d="M5.68549 62.2415C8.82335 62.2415 11.3671 59.6922 11.3671 56.5475C11.3671 53.4028 8.82335 50.8535 5.68549 50.8535C2.54764 50.8535 0.00390625 53.4028 0.00390625 56.5475C0.00390625 59.6922 2.54764 62.2415 5.68549 62.2415Z" fill="#73CDFF"/>
                <path d="M7.32094 34.442L7.37599 34.6898C7.97519 37.3874 10.643 39.0874 13.3347 38.4869L22.3847 36.4679C25.0764 35.8674 26.7727 33.1938 26.1735 30.4962L26.1184 30.2484C25.5192 27.5508 22.8515 25.8508 20.1598 26.4513L11.1097 28.4703C8.41805 29.0708 6.72175 31.7445 7.32094 34.442Z" fill="#282B89"/>
                <path d="M17.5167 17.713L17.2726 17.7808C14.6152 18.5189 13.058 21.2761 13.7944 23.9394L16.2706 32.8936C17.0071 35.5568 19.7583 37.1175 22.4158 36.3794L22.6599 36.3116C25.3173 35.5735 26.8745 32.8162 26.1381 30.153L23.6619 21.1988C22.9255 18.5355 20.1742 16.9749 17.5167 17.713Z" fill="#8688C0"/>
                <path d="M5.68159 28.2903C8.81944 28.2903 11.3632 25.741 11.3632 22.5963C11.3632 19.4516 8.81944 16.9023 5.68159 16.9023C2.54373 16.9023 0 19.4516 0 22.5963C0 25.741 2.54373 28.2903 5.68159 28.2903Z" fill="#8688C0"/>
                <path d="M15.1955 91.458C15.5199 90.8321 16.0166 90.3124 16.6267 89.9607C17.34 89.5861 18.1392 89.4063 18.9438 89.4394C19.7485 89.4726 20.5303 89.7175 21.2105 90.1495C21.8817 90.6484 22.3531 91.3709 22.5399 92.1871H20.693C20.5605 91.7982 20.3089 91.461 19.9741 91.2237C19.6039 90.9824 19.1682 90.8619 18.727 90.8786C18.41 90.8657 18.0944 90.9262 17.8045 91.0553C17.5146 91.1843 17.2583 91.3786 17.0554 91.6229C16.6397 92.1199 16.4318 92.8121 16.4318 93.7061C16.4318 94.6001 16.6483 95.2945 17.0554 95.7958C17.2572 96.0418 17.5132 96.2377 17.8032 96.3679C18.0932 96.4982 18.4094 96.5594 18.727 96.5466C19.7186 96.5466 20.374 96.1126 20.693 95.2446H22.5399C22.3271 96.0473 21.8512 96.7549 21.1888 97.254C20.4724 97.7671 19.607 98.0287 18.727 97.9983C17.9918 98.0124 17.2663 97.828 16.6267 97.4645C16.017 97.1093 15.5207 96.5878 15.1955 95.9607C14.8535 95.2608 14.6758 94.4919 14.6758 93.7126C14.6758 92.9334 14.8535 92.1644 15.1955 91.4645V91.458Z" fill="black"/>
                <path d="M25.7191 97.4579C25.0929 97.1064 24.5782 96.5852 24.2338 95.9541C23.8621 95.2644 23.6755 94.4898 23.6925 93.7061C23.6779 92.9228 23.8698 92.1496 24.2489 91.4645C24.6021 90.8297 25.1276 90.3082 25.7646 89.9607C26.4274 89.6157 27.1634 89.4355 27.9103 89.4355C28.6573 89.4355 29.3932 89.6157 30.0561 89.9607C30.6929 90.3084 31.2184 90.8298 31.5718 91.4645C31.9509 92.1496 32.1428 92.9228 32.1282 93.7061C32.1387 94.4894 31.9375 95.261 31.5458 95.9389C31.181 96.5814 30.6429 97.1078 29.9933 97.4579C29.3281 97.8161 28.5832 97.9997 27.8281 97.9917C27.0907 98.0011 26.3636 97.8171 25.7191 97.4579ZM29.0709 96.1863C29.4671 95.966 29.7923 95.6371 30.0085 95.238C30.2612 94.7681 30.3859 94.2397 30.3701 93.7061C30.3858 93.1784 30.2665 92.6555 30.0236 92.1871C29.8145 91.7931 29.4975 91.4671 29.1099 91.2475C28.733 91.0383 28.3087 90.9299 27.8779 90.9328C27.4504 90.9294 27.0295 91.0379 26.6567 91.2475C26.2733 91.4663 25.9624 91.793 25.7624 92.1871C25.5319 92.6593 25.419 93.1805 25.4333 93.7061C25.4333 94.5986 25.6607 95.2872 26.1154 95.7719C26.333 96.0099 26.5992 96.1984 26.8957 96.3245C27.1923 96.4507 27.5125 96.5116 27.8346 96.5031C28.2668 96.5045 28.6923 96.3955 29.0709 96.1863Z" fill="black"/>
                <path d="M45.5767 89.8406C46.0925 90.1105 46.5156 90.5293 46.7914 91.0428C47.0977 91.6367 47.2468 92.2994 47.2245 92.9675V97.8543H45.5269V93.2236C45.5269 92.4815 45.3421 91.9122 44.9726 91.5158C44.7763 91.3151 44.5396 91.1585 44.2783 91.0563C44.017 90.9542 43.737 90.9089 43.4569 90.9234C43.177 90.9104 42.8975 90.9564 42.6364 91.0585C42.3753 91.1605 42.1385 91.3163 41.9413 91.5158C41.5674 91.9137 41.3798 92.4829 41.3783 93.2236V97.8543H39.6873V93.2236C39.6873 92.4815 39.501 91.9216 39.133 91.5158C38.9366 91.3152 38.6999 91.1587 38.4386 91.0565C38.1773 90.9544 37.8974 90.909 37.6173 90.9234C37.3374 90.9107 37.0579 90.9568 36.7969 91.0588C36.5358 91.1609 36.299 91.3165 36.1016 91.5158C35.7263 91.9137 35.5387 92.4829 35.5387 93.2236V97.8543H33.8281V89.5694H35.5387V90.5133C35.8266 90.1674 36.1915 89.8939 36.604 89.7148C37.0368 89.5251 37.5043 89.4276 37.9767 89.4283C38.593 89.4172 39.2022 89.562 39.7479 89.8493C40.2621 90.1233 40.6806 90.548 40.9474 91.0667C41.2052 90.5571 41.6135 90.1396 42.1167 89.871C42.6405 89.5804 43.2306 89.4309 43.8294 89.437C44.436 89.4259 45.0361 89.5646 45.5767 89.8406Z" fill="black"/>
            </svg>

        </Box>
    );

    if (disabledLink) {
        return <>{logo}</>;
    }

    return <RouterLink to="/">{logo}</RouterLink>;
}
