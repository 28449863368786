import HomeIcon from '../../assets/navigation_home';
import StatIcon from '../../assets/navigation_statistics';
import RequestIcon from '../../assets/navigation_anfrangen';
import ServicesIcon from '../../assets/navigation_dienstle';
import UsersIcon from '../../assets/navigation_benutzer';
import FinancesIcon from '../../assets/navigation_finances';

export interface Menu {
    title: string
    href: string
    icon: () => JSX.Element
    roles?: string[]
}




const navigation_config: Menu[] = [
    {
        title: 'navigation_config_1',
        href: '/app/home',
        icon: HomeIcon
    }, {
        title: 'navigation_config_2',
        href: '/app/statistics',
        icon: StatIcon
    }, {
        title: 'navigation_config_3',
        href: '/app/request',
        icon: RequestIcon
    }, {
        title: 'navigation_config_4',
        href: '/app/services',
        icon: ServicesIcon
    },{
        title: 'navigation_config_5',
        href: '/app/users',
        icon: UsersIcon
    },{
        title: 'navigation_config_6',
        href: '/app/finances',
        icon: FinancesIcon
    },
]

export default navigation_config