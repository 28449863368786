import React from 'react';


function GuestGuard(props: any) {
  const { children } = props;

  return children;
}

export default GuestGuard;
