import BaseService from './BaseService';
import {AuthUrls} from './urls';

class AuthService {

    static get_userInfo = () => {
        return BaseService.postRequest(AuthUrls.GET_CONNECTED_INFO, {}, true);
    };

    static update_userInfo = (data: any) => {
        return BaseService.putRequest(AuthUrls.UPDATE_USER_INFO, data, true);
    };

    static update_password = (data: any) => {
        return BaseService.putRequest(AuthUrls.UPDATE_PASSWORD, data, true);
    };

    static passwordLogin = (info: any) => {
        return BaseService.postRequest(AuthUrls.LOGIN_USER, info, false);
    };

    static sendMailResetPassword = (email: string) => {
        return BaseService.postRequest(AuthUrls.SEND_MAIL_RESET_PASSWORD(email), {}, false);
    };

    static validateNewPassword = (payload: any) => {
        return BaseService.postRequest(AuthUrls.VALIDATE_NEW_PASSWORD, payload, false);
    };


}

export default AuthService;
