import {pxToRem, responsiveFontSizes} from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = 'Gantari, SF Pro, Roboto, sans-serif'; // Google Font

const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
        fontWeight: 700,
        lineHeight: 80 / 64,
        fontSize: pxToRem(42),
        letterSpacing: 2,
        ...responsiveFontSizes({sm: 42, md: 42, lg: 42}),
    },
    h2: {
        fontWeight: 700,
        lineHeight: 64 / 48,
        fontSize: pxToRem(32),
        ...responsiveFontSizes({sm: 32, md: 32, lg: 32}),
    },
    h3: {
        fontWeight: 600,
        lineHeight: 1.5,
        fontSize: pxToRem(25),
        // ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(20),
        ...responsiveFontSizes({sm: 19, md: 20, lg: 20}),
    },
    h5: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(18),
        ...responsiveFontSizes({sm: 19, md: 19, lg: 19}),
    },
    h6: {
        fontWeight: 600,
        lineHeight: 28 / 18,
        fontSize: pxToRem(16),
        ...responsiveFontSizes({sm: 15, md: 16, lg: 16}),
    },
    subtitle1: {
        fontWeight: 500,
        lineHeight: 1.5,
        fontSize: pxToRem(16),
    },
    subtitle2: {
        fontWeight: 600,
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
    },
    body1: {
        lineHeight: 1.5,
        fontSize: pxToRem(16),
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    body2: {
        lineHeight: 22 / 14,
        fontSize: pxToRem(14),
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    caption: {
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
    },
    overline: {
        fontWeight: 700,
        lineHeight: 1.5,
        fontSize: pxToRem(12),
        textTransform: 'uppercase',
    },
    button: {
        fontWeight: 700,
        lineHeight: 24 / 14,
        fontSize: pxToRem(14),
        textTransform: 'initial',
    },
};

export default typography;
