import {Pagination} from 'types';
import BaseService from './BaseService';
import {UserUrls} from './urls';
import {UpdateAdminProps} from "../../views/profile/logic/types";
import {AddUserPayload} from "../../components/AddOrUpdateUser/types";

class UserService {

    static get_all_user = (range: Pagination) => {
        return BaseService.getRequest(UserUrls.LIST_USERS(range), true);
    };

    static add_user = (data: AddUserPayload) => {
        return BaseService.postRequest(UserUrls.ADD_USER, data, true)
    }
    static update_user = (data: AddUserPayload) => {
        return BaseService.putRequest(UserUrls.UPDATE_USER, data, true)
    }

    static delete_user = (uuid: string) => {
        return BaseService.deleteRequest(UserUrls.DELETE_USER(uuid), {}, true)
    }

    static get_user_details = (uuid: string) => {
        return BaseService.getRequest(UserUrls.GET_USER_DETAIL(uuid), true)
    }

    static bloc_unblock_user = (uuid: string) => {
        return BaseService.putRequest(UserUrls.DELETE_USER(uuid), {}, true)
    }
    // User request service

    static get_all_user_requests = (range: Pagination) => {
        return BaseService.getRequest(UserUrls.LIST_USER_REQUEST(range), true);
    }

    // User reviews service

    static get_all_user_reviews = (range: Pagination) => {
        return BaseService.getRequest(UserUrls.LIST_USER_REVIEWS(range), true);
    }


    //connected user
    static update_my_password = (current_password: string, new_password: string) => {
        return BaseService.putRequest(UserUrls.UPDATE_MY_PASSWORD, {current_password, new_password}, true);
    }
    static update_my_avatar = (base64: string) => {
        return BaseService.putRequest(UserUrls.UPDATE_MY_AVATAR, {base64}, true);
    }
    static update_my_infos = (data: UpdateAdminProps) => {
        return BaseService.putRequest(UserUrls.UPDATE_MY_INFOS, data, true);
    }

}

export default UserService;
