import {Box} from "@mui/material";
import React from "react";

interface DotProps {
    size?: number;
    color?: string;
}

const Dot = (props: DotProps) => {
    const {size = 4, color = "#29CB39"} = props;
    return (
        <Box
            sx={{
                width: size,
                height: size,
                borderRadius: "50%",
                backgroundColor: color
            }}
        />
    )
}

export default Dot