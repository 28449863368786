// ----------------------------------------------------------------------

export default function Dialog(theme) {
    return {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    // backdropFilter: "blur(3px)",
                    // backgroundColor: 'rgba(0,0,30,0.4)'
                },
            },
        },
    };
}