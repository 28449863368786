// ----------------------------------------------------------------------

export default function Pagination(theme) {
  return {
    MuiPagination: {
      styleOverrides: {
        ul: {
          'li': {
            '&:last-of-type': {
              'button': {
                borderRadius: 0,
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
              }
            },
            '&:first-of-type': {
              'button': {
                borderRadius: 0,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
              }
            },
          }
        }
      },
    },
  };
}
