// ----------------------------------------------------------------------

export default function TableCell(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 400
        },
      },
    },
  };
}
