import {AddUserPayload} from "../types";
import {useState} from "react";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {UserService} from "../../../services/api";
import {useQueryClient} from "react-query";
import {default_users_key, payload_users_key, UserCrudType} from "../../../views/users/logic/user_hooks";
import {UsersListResponse} from "../../../views/users/logic/user_types";

type AddOrUpdateUser = (payload: AddUserPayload, onSuccess: () => void) => void

type UseAddOrUpdateUser = () => [
    loading: boolean,
    updateFunction: AddOrUpdateUser
]

export const useAddOrUpdateUser: UseAddOrUpdateUser = () => {
    const [loading, setLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const queryClient = useQueryClient();

    const addOrCreateUser: AddOrUpdateUser = async (payload, onSuccess) => {
        setLoading(true);

        return UserService[payload.public_id ? "update_user" : "add_user"](payload)
            .then(async (response: Response) => {
                if ([200, 201].includes(response.status)) {
                    enqueueSnackbar(t(payload.public_id ? 'update_infos_success_message' : "add_user_success_message"), {
                        variant: 'success'
                    });
                    const key = queryClient.getQueryData<UserCrudType>(payload_users_key) || default_users_key;
                    const responseData = await response.json();
                    queryClient.setQueryData<UsersListResponse>(key, (old) => {
                        const users = old || {
                            pages: 1,
                            data: [],
                            current_page: 1,
                            total: 10,
                            page: 1,
                            per_page: 10,
                        };

                        return {
                            ...users,
                            data: payload?.public_id ? [...(old?.data || [])].map(user => (user.public_id == responseData.public_id) ? responseData : user) : [responseData, ...(old?.data || [])]
                        }
                    });
                    onSuccess();
                } else {
                    let {detail} = await response.json();
                    enqueueSnackbar(detail, {
                        variant: 'error'
                    });
                }
            })
            .catch((err: any) => {
                enqueueSnackbar(`${t('internet_connection_error')}`, {
                    variant: 'error'
                })
            })
            .finally(() => setLoading(false))
    }
    return [loading, addOrCreateUser]
}